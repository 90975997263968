import axios from 'axios'
import { defaultReqInterceptors, defaultResInterceptors, defaultResInterceptorsError } from '../main'

const adminAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/admin',
    params: {
        place: localStorage.getItem('place') || 'akari'
    }
})

adminAxios.interceptors.request.use(config => defaultReqInterceptors(config))
adminAxios.interceptors.response.use(
    response => defaultResInterceptors(response),
    error => defaultResInterceptorsError(error)
)

// Admin
export function checkAdmin() {
    return adminAxios.get()
}

// HH
export function getAdminActiveHappyHours() {
    return adminAxios.get('/happy_hours.json')
}

// Menu
export function getDishes() {
    return adminAxios.get('/dishes.json')
}

export function getDishesStats() {
    return adminAxios.get('/dishes/stats.json')
}
export function getDishesStatsAddress(filters) {
    return adminAxios.get('/dishes/stats_address.json', { params: {...adminAxios.defaults.params, ...filters} })
}

export function getDish(id) {
    return adminAxios.get('/dishes/' + id + '.json')
}

export function createDish(data) {
    return adminAxios.post('/dishes.json', data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function updateDish(id, data) {
    return adminAxios.put('/dishes/' + id + '.json', data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function disableDish(id) {
    return adminAxios.put('/dishes/' + id + '/disable.json')
}

export function enableDish(id) {
    return adminAxios.put('/dishes/' + id + '/enable.json')
}

export function addAddon(id, params) {
    return adminAxios.post('/dishes/' + id + '/add_addon.json', { addon: params })
}

export function updateAddon(id, addon_id, params) {
    return adminAxios.put('/dishes/' + id + '/update_addon.json', { addon_id: addon_id, addon: params })
}

export function destroyAddon(id, addon_id) {
    return adminAxios.post('/dishes/' + id + '/destroy_addon.json', { addon_id })
}

export function getCategories() {
    return adminAxios.get('/categories.json')
}

export function getCategory(id) {
    return adminAxios.get('/categories/' + id + '.json')
}

export function createCategory(params) {
    return adminAxios.post('/categories.json', { category: params })
}

export function updateCategory(id, params) {
    return adminAxios.put('/categories/' + id + '.json', { category: params })
}

export function destroyCategory(id) {
    return adminAxios.delete('/categories/' + id + '.json')
}

export function getUberMenu(restaurant = 'NovitusOnline') {
    return adminAxios.get('/dishes/uber_menu.json?restaurant=' + restaurant)
}
export function getUberDeliveryMenu() {
    return getUberMenu('Delivery')
}
export function getUberKalmarMenu() {
    return getUberMenu('Kalmar')
}

export function getHappyHours(id) {
    return adminAxios.get('/dishes/' + id + '/happy_hours.json')
}

export function saveHappyHour(id, data) {
    return adminAxios.put('/dishes/' + id + '/save_happy_hours.json', { happy_hour: data })
}

// SpecialMenus
export function getSpecialMenus() {
    return adminAxios.get('/special_menus.json')
}
export function getSpecialMenuOrders(id) {
    return adminAxios.get('/special_menus/' + id + '/orders.json')
}
export function getSpecialMenu(id) {
    return adminAxios.get('/special_menus/' + id + '.json')
}
export function updateSpecialMenu(id, params) {
    return adminAxios.put('/special_menus/' + id + '.json', { special_menu: params })
}
export function createSpecialMenu(params) {
    return adminAxios.post('/special_menus.json', { special_menu: params })
}
export function destroySpecialMenu(id) {
    return adminAxios.delete('/special_menus/' + id + '.json')
}

// DisabledDates
export function getDisabledDates() {
    return adminAxios.get('/disabled_dates.json')
}
export function getDisabledDate(id) {
    return adminAxios.get('/disabled_dates/' + id + '.json')
}
export function updateDisabledDate(id, params) {
    return adminAxios.put('/disabled_dates/' + id + '.json', { disabled_date: params })
}
export function createDisabledDate(params) {
    return adminAxios.post('/disabled_dates.json', { disabled_date: params })
}
export function destroyDisabledDate(id) {
    return adminAxios.delete('/disabled_dates/' + id + '.json')
}

// Deliveries
export function getDeliveries() {
    return adminAxios.get('/deliveries.json')
}
export function getDelivery(id) {
    return adminAxios.get('/deliveries/' + id + '.json')
}
export function updateDelivery(id, params) {
    return adminAxios.put('/deliveries/' + id + '.json', { delivery: params })
}
export function createDelivery(params) {
    return adminAxios.post('/deliveries.json', { delivery: params })
}
export function destroyDelivery(id) {
    return adminAxios.delete('/deliveries/' + id + '.json')
}

// FreeDeliveryAreas
export function getFreeDeliveryAreas() {
    return adminAxios.get('/free_delivery_areas.json')
}
export function getFreeDeliveryArea(id) {
    return adminAxios.get('/free_delivery_areas/' + id + '.json')
}
export function updateFreeDeliveryArea(id, params) {
    return adminAxios.put('/free_delivery_areas/' + id + '.json', { free_delivery_area: params })
}
export function createFreeDeliveryArea(params) {
    return adminAxios.post('/free_delivery_areas.json', { free_delivery_area: params })
}
export function destroyFreeDeliveryArea(id) {
    return adminAxios.delete('/free_delivery_areas/' + id + '.json')
}

// SoupOfTheDays
export function getSoupOfTheDays() {
    return adminAxios.get('/soup_of_the_days.json')
}
export function getSoupOfTheDay(id) {
    return adminAxios.get('/soup_of_the_days/' + id + '.json')
}
export function updateSoupOfTheDay(id, data) {
    return adminAxios.put('/soup_of_the_days/' + id + '.json', data)
}
export function toggleSoupOfTheDayIsActive(id) {
    return adminAxios.put('/soup_of_the_days/' + id + '/toggle_active.json')
}

// Users
export function adminGetUsers(filters) {
    return adminAxios.get('/users.json', { params: {...adminAxios.defaults.params, ...filters} })
}

export function adminGetUser(id) {
    return adminAxios.get('/users/' + id + '.json')
}

export function adminGetUserRoles() {
    return adminAxios.get('/users/get_roles.json')
}

export function adminSetUserRole(id, role) {
    return adminAxios.put('/users/' + id + '/set_role.json', { user: { role } })
}

export function adminUpdateUserEmail(id, data) {
    return adminAxios.put('/users/' + id + '/update_email.json', { user: { email: data.email } })
}

export function adminUpdateUserPassword(id, data) {
    return adminAxios.put('/users/' + id + '/update_password.json', { user: { password: data.password } })
}

export function adminLogoutUser(id) {
    return adminAxios.put('/users/' + id + '/logout_user.json')
}

// Settings
export function getSettings() {
    return adminAxios.get('/settings.json')
}

export function updateSettings(data) {
    return adminAxios.put('/settings.json', data)
}

// Revenue
export function getRevenues(filters) {
    return adminAxios.get('/daily_revenues.json', { params: {...adminAxios.defaults.params, ...filters} })
}
export function getRevenue(id) {
    return adminAxios.get('/daily_revenues/' + id + '.json')
}
export function newRevenue() {
    return adminAxios.get('/daily_revenues/new.json')
}
export function createRevenue(params) {
    return adminAxios.post('/daily_revenues.json', { daily_revenue: params })
}

// Promocodes
export function adminGetPromocodes(filters) {
    return adminAxios.get('/promocodes.json', { params: {...adminAxios.defaults.params, ...filters} })
}

export function adminCreatePromocode(data) {
    return adminAxios.post('/promocodes.json', { promocode: data })
}

export function adminDisablePromocode(id) {
    return adminAxios.put('/promocodes/' + id + '/disable.json')
}

// Invoices
export function adminGetInvoices(filters) {
    return adminAxios.get("/invoices.json", { params: {...adminAxios.defaults.params, ...filters} })
}

export function adminGetInvoice(id) {
    return adminAxios.get("/invoices/" + id + ".json")
}

export function adminSearchInvoice(nip) {
    return adminAxios.get("/invoices/" + nip + "/search.json")
}

export function adminNewInvoice() {
    return adminAxios.get("/invoices/new.json")
}

export function adminCreateInvoice(data) {
    return adminAxios.post("/invoices.json", { invoice: data })
}

export function adminUpdateInvoice(id, data) {
    return adminAxios.put("/invoices/" + id + ".json", { invoice: data })
}

export function adminDestroyInvoice(id) {
    return adminAxios.delete("/invoices/" + id + ".json")
}

// Orders
export function adminGetOrders(filters) {
    return adminAxios.get('/orders.json', { params: {...adminAxios.defaults.params, ...filters} } )
}

export function adminGetCanceledOrders() {
    return adminAxios.get('/orders/canceled_orders.json')
}

export function adminGetTables() {
    return adminAxios.get('/orders/tables.json')
}

export function adminUpdateTable(table_id, data) {
    return adminAxios.put('/orders/' + table_id + '/update_table.json', { table: data })
}

export function adminGetOrderChanges() {
    return adminAxios.get('/orders/order_changes.json')
}

export function getAlcoholStats(data) {
    return adminAxios.get('/orders/alcohol.json?from=' + data["from"] + '&to=' + data["to"])
}

export function getPlasticStats(data) {
    return adminAxios.get('/orders/plastic.json?from=' + data["from"] + '&to=' + data["to"])
}

// Add more
export function getAddMores(filters) {
    return adminAxios.get('/add_mores.json', { params: {...adminAxios.defaults.params, ...filters} })
}

export function getAddMore(id) {
    return adminAxios.get('/add_mores/' + id + '.json')
}

export function createAddMore(params) {
    return adminAxios.post('/add_mores.json', { add_more: params })
}

export function updateAddMore(id, params) {
    return adminAxios.put('/add_mores/' + id + '.json', { add_more: params })
}

export function destroyAddMore(id) {
    return adminAxios.delete('/add_mores/' + id + '.json')
}

// Custom Receipt
export function createCustomReceipt(params) {
    return adminAxios.post('/custom_receipts.json', { custom_receipt: params })
}
export function adminCustomReceipts() {
    return adminAxios.get('/custom_receipts.json')
}
export function adminCustomReceipt(id) {
    return adminAxios.get('/custom_receipts/' + id + '.json')
}
export function adminPrintCustomReceipt(id) {
    return adminAxios.put('/custom_receipts/' + id + '/print.json')
}
export function adminStopCustomReceipt(id) {
    return adminAxios.put('/custom_receipts/' + id + '/stop.json')
}
export function adminDestroyCustomReceipt(id) {
    return adminAxios.delete('/custom_receipts/' + id + '.json')
}