<template>
    <li v-if="specialMenus.length <= 0">
        <router-link :to='{name: "Menu"}' class="button is-bordered">
            <span>{{ $t('order.button') }}</span>
        </router-link>
    </li>
	<li
        v-else
		class="order-buton-changer"
		@mouseover='openOrderMenu()'
		@mouseout='closeOrderMenu()'
	>
		<button class="order-buton-changer__trigger">
			<span>{{ $t('order.button') }}</span>
			<i v-if='orderButtonMenuOpen' class="fas fa-chevron-up is-visible"></i>
			<i v-else class="fas fa-chevron-down is-visible"></i>
		</button>
		<ul
			class="order-buton-changer__menu"
			:class="{'is-open': orderButtonMenuOpen}"
			@click='closeOrderMenu()'
			@mouseover='onOrderMenuHover()'
			@mouseout='offOrderMenuHover()'
		>
            <li>
                <router-link :to='{name: "Menu"}' class="button is-red is-small">
                    <span>{{ $t('order.normalButton') }}</span>
                </router-link>
            </li>
            <li v-for="specialMenu in specialMenus" :key="`specialMenu${specialMenu.id}`">
            <router-link :to='{name: "SpecialMenu", params: { slug: specialMenu.slug }}' class="button is-red is-small special-menu-button">
                <span>{{ specialMenu.title }}</span>
            </router-link>
            </li>
		</ul>
	</li>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data: () => ({
		orderButtonMenuOpen: document.documentElement.clientWidth < 1100,
		orderButtonMenuHover: false,
	}),
    computed: {
        ...mapGetters({
            specialMenus: 'specialMenus',
        }),
    },
	methods: {
		openOrderMenu () {
			setTimeout(() => {
				if (document.documentElement.clientWidth >= 1100) {
					this.orderButtonMenuOpen = true
				}
			}, 100)
		},
		closeOrderMenu () {
			setTimeout(() => {
				if (document.documentElement.clientWidth >= 1100 && !this.orderButtonMenuHover) {
					this.orderButtonMenuOpen = false
				}
			}, 100)
		},
		onOrderMenuHover () {
			this.orderButtonMenuHover = true
		},
		offOrderMenuHover () {
			this.orderButtonMenuHover = false
			this.closeOrderMenu()
		},
	}
}
</script>

<style lang="scss">
@import '../../assets/css/breakpoints.scss';

.order-buton-changer {
	position: relative;

	&__trigger {
		@include bp-mobilenav {
			display: none !important;
		}

		background-color: transparent;
		border-color: transparent;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;
        color: var(--white);

		i {
			color: var(--white);
			margin-left: 5px;
		}
	}

	&__menu {
		display: none;

		position: absolute;
        width: 100%;
		bottom: 0;
		right: 0;
        transform: translateY(99%);
		align-items: stretch;
		justify-content: flex-end;

		@include bp-mobilenav {
			display: flex !important;
			flex-direction: column;
			position: relative;
			transform: translate(0%, 0);
		}

		&.is-open {
			display: flex;
		}

		> li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 2.5px;
		}

        .button {
            white-space: nowrap;
        }
	}
}
</style>